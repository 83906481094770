import React from "react";
import Navbar from "../../components/Navbar";
import ContactUs from "../../assets/img/contact.png";

const Contact = () => {
  return (
    <div>
      <Navbar />

      <section id="contact" className="h-full md:h-screen md:grid grid-cols-4">
        <div className="col-span-1 flex items-center mx-auto">
          <img src={ContactUs} alt="Tap tap contact" />
        </div>

        <div className="col-span-3 bg-black text-white flex">
          <div className="p-8 md:mx-20">
            <h1 className="mt-20 text-5xl font-bold mb-20 leading-tight">
              Contact Us
            </h1>

            <form className="gap-4 mt-16 flex flex-col w-full">
              <input
                type="text"
                placeholder="Name"
                className="p-4 w-52 mt-4 md:w-80 bg-transparent"
                // onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="p-4 w-52 mt-4 md:w-80 bg-transparent"
                // onChange={(e) => setEmail(e.target.value)}
                required
              />

              <select
                for="reasons"
                className="px-3 mt-2 w-full opacity-50 h-[46px] bg-transparent"
                //   onChange={(e) => setReasonForContact(e.target.value)}
              >
                <option value="">Reason for contact</option>
                <option value="Demo">Demo</option>
                <option value="Others">Others</option>
              </select>

              <textarea
                id="details"
                placeholder="Message"
                className="p-4 rounded-md mt-4 w-full h-[200px] bg-transparent"
                required
              ></textarea>

              <button
                // onClick={submit}
                className="bg-white px-2 h-9 w- md:h-12 md:py-3  md:px-6 ms-1 rounded-full text-black hover:scale-[1.02] duration-300"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
