import React from 'react';
import Hero from "../assets/img/TaptapHero.png";

const HeroButton = () => {
  return (
    <div className="relative w-72 h-full hidden md:block">
      <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
        {/* <span className="text-white text-2xl z-10">Tap</span> */}
        <img src={Hero} alt="Tap tap hero"/> 
        <div className="absolute inset-0 overflow-hidden">
          <div className="glow-animation" />
        </div>
      </div>
      <style jsx>{`
        .glow-animation {
          position: absolute;
          top: 0;
          left: -100%;
          width: 200%;
          height: 100%;
          background: linear-gradient(
            105deg,
            transparent 0%,
            transparent 40%,
            rgba(255,255,255,0.1) 50%,
            transparent 60%,
            transparent 100%
          );
          animation: subtle-glow 3s linear infinite;
        }
        @keyframes subtle-glow {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(50%);
          }
        }
      `}</style>
    </div>
  );
};

export default HeroButton;