import { useState } from "react";
import Navbar from "../../components/Navbar";
import ContactUs from "../../assets/img/waitlist.png";
import axios from "axios";
import { toast } from "react-toastify";

const Waitlist = () => {

    const [firstname, setfirstName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const submit = async (e) => {
        e.preventDefault();
    
        const data = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: firstname,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: email,
            },
            {
              objectTypeId: "0-1",
              name: "phone",
              value: phone,
            },
          ],
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: "I agree to allow Tap Tap to store and process my personal data.",
              communications: [
                {
                  value: true,
                  subscriptionTypeId: 999,
                  text: "I agree to receive marketing communications from Tap Tap.",
                },
              ],
            },
          },
        };
    
       
        const response = await axios.post(
          `https://api.hsforms.com/submissions/v3/integration/submit/47185428/07eeec0c-f129-41ba-8eeb-d279eb1f61f0`,
          data
        );
        if (response.status === 200) {
          toast.success("Form submitted. Thank you for joining our waitlist");
        } else {
          toast.error("Please fill input fields with (*)");
        }
    
        setEmail("");
    
        // window.location.reload();
      };
  return (
    <div>
      <Navbar />

      <section id="contact" className="h-full md:h-screen md:grid grid-cols-4">
        <div className="col-span-1 flex items-center mx-auto my-8 md:my-0">
          <img src={ContactUs} alt="Tap tap contact" />
        </div>

        <div className="col-span-3 bg-black text-white flex">
          <div className="p-8 md:mx-20">
            <h1 className="mt-20 text-5xl font-bold leading-tight">
            Join Waitlist
            </h1>
            <p className="my-3">Be among the first to try Tap tap.</p>

            <form className="gap-4 mt-24 flex flex-col w-full">
              <input
                type="text"
                placeholder="Name"
                className="p-4 font-bold text-3xl w-52 mt-4 md:w-80 bg-transparent"
                onChange={(e) => setfirstName(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="p-4 font-bold text-3xl w-52 mt-4 md:w-[500px] bg-transparent"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Phone Number"
                className="p-4 font-bold text-3xl w-52 mt-4 md:w-80 bg-transparent"
                onChange={(e) => setPhone(e.target.value)}
                required
              />



              <button
                onClick={submit}
                className="bg-white px-2 h-9 my-16 md:h-12 md:py-3  md:px-6 ms-1 rounded-full text-black hover:scale-[1.02] duration-300"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Waitlist;
