import { useState } from "react";
import Logo from "../assets/icons/TaptapLogo.svg";
import hamburger from "../assets/icons/hamburger.svg";
import close from "../assets/icons/X.svg";

const Navbar = () => {
  const navBar = [
    { title: "Home", url: "/" },
    // { title: "About", url: "/about" },
    { title: "Contact Us", url: "/contact" },
    { title: "FAQ", url: "#faq" },
  ];
  const [nav, setNav] = useState(false);


  const handleClick = () => {
    setNav(!nav);
  };


  return (
    <div>
      <div
        className="hidden md:block fixed z-20 w-full  duration-300"
      >
        <nav
          className="hidden md:grid grid-cols-4"
        >
          <div className="flex items-center ps-12 bg-white">
            <a href="/" className="font-bold text-3xl">
              <img src={Logo} alt="Tap Tap Logo" />
            </a>
          </div>

          <div className="flex items-center col-span-3 py-5 pe-12 justify-end bg-black">
            <ul className="flex lg:ms-12 text-white">
              {navBar.map(({ title, url }, idx) => (
                <li key={idx}>
                  <a href={url} className="mx-3 hover:opacity-70">
                    {title}
                  </a>
                </li>
              ))}
            </ul>
            <a
              href="/waitlist"
              className="bg-white ms-4 py-2 px-2 lg:px-6 rounded-full text-black hover:opacity-70 duration-300"
            >
              Join Waitlist
            </a>
          </div>
        </nav>
      </div>

      <div className="container mx-auto vh-10 flex md:hidden items-center justify-between px-3 ">
        <div className="p-3 z-30">
          <a href="/" className="font-bold text-3xl">
            <img src={Logo} alt="TapTap Logo" />
          </a>
        </div>
        <div onClick={handleClick} className="cursor-pointer p-3 z-30">
          {!nav ? (
            <img src={hamburger} alt="logo" className="" />
          ) : (
            <img src={close} alt="" className="" />
          )}
        </div>
      </div>
      <div
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 bg-white w-full h-screen flex flex-col justify-center items-center z-20"
        }
      >
        <ul className="text-3xl font-bold text-center">
          {navBar.map(({ title, url }, idx) => (
            <li key={idx} className="py-5">
              <a href={url} onClick={handleClick}>
                {title}
              </a>
            </li>
          ))}
        </ul>
        <a
          href="/waitlist"
          className="bg-black py-2 px-8 mt-10 rounded-full text-white hover:opacity-70 duration-300"
        >
          Join Waitlist
        </a>
      </div>
    </div>
  );
};

export default Navbar;
