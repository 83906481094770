
import Power from '../assets/img/power.svg'
import Airtime from '../assets/img/airtime.svg'
import Data from '../assets/img/data.svg'
import Cable from '../assets/img/cable.svg'
import Waste from '../assets/img/waste.svg'
import Religion from '../assets/img/religion.svg'
import Betting from '../assets/img/betting.svg'

const Widgets = () => {
  const boxes = [
    { id: 'power', label: Power, top: '12%', left: '55%' },
    { id: 'data', label: Data, top: '20%', left: '5%' },
    { id: 'airtime', label: Airtime, top: '35%', left: '55%' },
    { id: 'cable', label: Cable, top: '50%', left: '5%' },
    { id: 'waste', label: Waste, top: '65%', left: '55%' },
    { id: 'religion', label: Religion, top: '80%', left: '2%' },
    { id: 'betting', label: Betting, top: '90%', left: '55%' },
  ];

  return (
    <div className="relative w-screen md:w-64 h-screen md:h-full  md:block">
      {boxes.map((box, index) => (
        <div
          key={box.id}
          className="absolute flex items-center justify-center"
          style={{
            top: box.top,
            left: box.left,
            animation: `float 3s ease-in-out infinite ${index * 0.5}s`,
          }}
        >
          <img 
            src={box.label} 
            alt={box.label}
            className="w-full h-full object-contain"
          />
        </div>
      ))}
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-8px) translateX(-2px); }
        }
      `}</style>
    </div>
  );
};

export default Widgets;