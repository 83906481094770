
export default function FaqComponent() {
    return (
        <div className="flex items-center mt-10 md:mt-10">
            <div className="">
                <div className="flex flex-col  w-full">
                    
                    <div className="space-y-5">
                        <details className="w-full text-left rounded-2xl ">
                            <summary className="py-6  md:w- text-4xl font-bold">
                            What is TapTap?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Answer                       </p>
                        </details>
                        <details className="w-full text-left rounded-2xl  ">
                            <summary className="py-6 text-4xl font-bold">
                            What is TapTap?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Answer                        </p>
                        </details>
                        <details className="w-full text-left rounded-2xl  ">
                            <summary className="py-6 text-4xl font-bold">
                            What is TapTap?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Answer                        </p>
                        </details>
                       
                      
                    </div>
                </div>
            </div>
        </div>
    );
}